<template>
  <b-container class="">
    <b-row>
      <b-col cols="12">
        <div class="text-center mb-5">
          <!-- <img style="width:100%;height:auto;" src="https://storage.googleapis.com/pickitperfect-public-bucket-1/pickitperfect-user-images/logo_motto.png"/> -->
          <h1 class="my-3" style="font-size:8rem;">Pick it Perfect</h1>
          <h2 class="mt-2 mb-5">Never give or receive an unwanted gift again!</h2>
         
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <p class="mb-3">
          Pick it Perfect introduces
an online personal profile that simplifies the process of thoughtful gifting. Users can
effortlessly access the profiles of individuals they intend to buy gifts for, gaining insights
into their preferences. Each user maintains a Preference Profile, along with a Wish List
feature for specific desired items.
        </p>
        <p class="mb-3">
          Pick it Perfect empowers users to control the visibility of their information, deciding what
is public, private, or accessible only to selected users. With Pick it Perfect you can be
confident that you’re always gifting exactly what your loved ones want, contributing to a
more sustainable and thoughtful gift-giving experience.
        </p>
      </b-col>
    </b-row>
    

  </b-container>
</template>

<script>
// import ContactFormAnonymous from '../components/ContactFormAnonymous.vue'
// import SmileySvg from '../../assets/icons/SmileySvg.vue'

export default {
  name: 'AboutPage',
  components: {
    // SmileySvg
    // ContactFormAnonymous
  },
  data() {
    return {

    }
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.currentUser
    // },
  },
	mounted() {

  },
  created() {

  },
  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
  // thin is thinner than light
  // font-family: Larsseit-Light;
}

.card {
  border-radius: 0.75rem;
}


div, span {
  font-family: Larsseit-Light;
}

.regular {
  font-size: 1.25rem;
}
.large {
  font-size: 2rem;
}

.small {
  font-size: 1rem;
}

</style>
