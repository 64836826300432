


<template>
	<div class="mt-auto">
		<div
			id="footer"
			class="py-5 pl-4 brand-color-bg"
		>
			<!-- <b-row> -->
				<!-- <b-col cols="6" style="text-align:left;"> -->
					<div>
						<a href="/about#/contact" style="color:white;">Support</a>
					</div>

					<div>
						<a href="/about#/about" style="color:white;">About</a>
					</div>

					<div>
						<a href="/about#/faq" style="color:white;">FAQ</a>
					</div>

					<div>
						<a href="/about#/terms" style="color:white;">Terms</a>
					</div>

					<div>
						<a href="/about#/privacy" style="color:white;">Privacy Policy</a>
					</div>
				<!-- </b-col> -->
			<!-- </b-row> -->
			<div class="text-center">
				<!-- © 2023 Pick it Perfect LLC -->
				2023 Pick it Perfect LLC
			</div>
		</div>
	</div>
</template>



<script>

export default {
  name: 'MarketingFooter',
  components: {
  },
  data() {
    return {
    }
  },

  computed: {
  },

  created() {
  },

  methods: {

  },

}
</script>

<style lang="scss" scoped>

#footer {
	min-height: 100px;
	font-size: 16px;
	color:white;
	width:100%;
	// text-align: center;
	// background: #00a2e1 !important;
	// background: rgb(109, 183, 237) !important;
}


</style>
