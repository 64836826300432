<template>
  <b-container class="">
    <b-row>
      <b-col cols="12">
        <div class="text-center mb-5">
          <h4 class="my-3" style="">Frequently Asked Questions</h4>
         
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <h3>SHARING</h3>
        <b-card class="mb-3">
          <div v-b-toggle.collapse-1 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I share my profile with a friend?</span>
          </div>
          <b-collapse id="collapse-1" class="mt-2">
            <p class="card-text answer">From your Profile page, click on the “Share Profile” button in the top right corner. Your personal link will then be copied to your clipboard for you to share.</p>
          </b-collapse>
        </b-card>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-2 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I change my Wish List privacy settings?</span>
          </div>
          <b-collapse id="collapse-2" class="mt-2">
            <p class="card-text answer">Click on the Edit List icon next to your Wish List and under List Privacy Settings you can choose from the following options: Visible - visible to your Connections OR Private - visible only to you OR Select Users Only - visible only to the Connections you specify.</p>
          </b-collapse>
        </b-card>


        <b-card class="mb-3">
          <div v-b-toggle.collapse-3 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How do I change my privacy settings?</span>
          </div>
          <b-collapse id="collapse-3" class="mt-2">
            <p class="card-text answer">From your Settings page, you can choose one of three options: Visible - visible to your Connections; Private - visible only to you; Select Users Only - visible only to users you select here.</p>
          </b-collapse>
        </b-card>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-4 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I save changes to my profile?</span>
          </div>
          <b-collapse id="collapse-4" class="mt-2">
            <p class="card-text answer">All changes are automatically saved.</p>
          </b-collapse>
        </b-card>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-5 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I delete a Connection?</span>
          </div>
          <b-collapse id="collapse-5" class="mt-2">
            <p class="card-text answer">From your Connection's page, click on the “Remove” button.</p>
          </b-collapse>
        </b-card>

        <h3>PREFERENCES</h3>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-6 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I create a new category for my Preference Profile if I don't see what I'm looking for?</span>
          </div>
          <b-collapse id="collapse-6" class="mt-2">
            <p class="card-text answer">From your Profile page, go to the Preference Profile section and click on “Add” and then “Add Category.”</p>
          </b-collapse>
        </b-card>


        <b-card class="mb-3">
          <div v-b-toggle.collapse-7 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I delete something in my Preference Profile?</span>
          </div>
          <b-collapse id="collapse-7" class="mt-2">
            <p class="card-text answer">From the Preference Profile section in your profile, click on a category button. From there, find the item you'd like to delete and click on the X icon next to the item you'd like to remove.</p>
          </b-collapse>
        </b-card>

        
        <h3>WISHLIST</h3>
        <b-card class="mb-3">
          <div v-b-toggle.collapse-8 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I add items to my Wish List?</span>
          </div>
          <b-collapse id="collapse-8" class="mt-2">
            <p class="card-text answer">From your Profile page, scroll down to the Wish List section and choose the list you'd like to add items to.  Click on “Add item” sign to add a link.</p>
          </b-collapse>
        </b-card>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-9 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I create a new Wish List?</span>
          </div>
          <b-collapse id="collapse-9" class="mt-2">
            <p class="card-text answer">From your Profile page, scroll down to the Wish List section and click on “Add.”</p>
          </b-collapse>
        </b-card>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-10 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I delete a Wish List or Wish List item?</span>
          </div>
          <b-collapse id="collapse-10" class="mt-2">
            <p class="card-text answer">From the Wish List section on your profile, click on the trash can icon next to the list or item you want to remove. </p>
          </b-collapse>
        </b-card>

      
        <h3>NO THANKS</h3>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-11 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How do I let someone know what I do not want to receive as a gift?</span>
          </div>
          <b-collapse id="collapse-11" class="mt-2">
            <p class="card-text answer">From your Profile page, scroll down to the No Thanks section, type in the blank field and click on the “+” sign to add items.</p>
          </b-collapse>
        </b-card>

        <h3>KIDS</h3>

        <b-card class="mb-3">
          <div v-b-toggle.collapse-12 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I add my children to my profile?</span>
          </div>
          <b-collapse id="collapse-12" class="mt-2">
            <p class="card-text answer">From your Profile page, click on the “+” symbol below your profile image. Enter your child's information and then click on “Add Kid.”</p>
          </b-collapse>
        </b-card>

        
        <b-card class="mb-3">
          <div v-b-toggle.collapse-13 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I share my child's profile?</span>
          </div>
          <b-collapse id="collapse-13" class="mt-2">
            <p class="card-text answer">To share your child's profile, you would share your profile. Kids under the age of 14 can only be viewed under a guardian's profile. </p>
          </b-collapse>
        </b-card>

        


        <b-card class="mb-3">
          <div v-b-toggle.collapse-14 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">Are my kids’ profiles searchable?</span>
          </div>
          <b-collapse id="collapse-14" class="mt-2">
            <p class="card-text answer">Kids under the age of 14 are not searchable and can only be viewed under a guardian's profile.</p>
          </b-collapse>
        </b-card>

        <h3>CALENDAR</h3>
        <b-card class="mb-3">
          <div v-b-toggle.collapse-15 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question">How can I add a new date to my calendar?</span>
          </div>
          <b-collapse id="collapse-15" class="mt-2">
            <p class="card-text answer">From your Profile page, click on the “My Dates” button below your profile image. Click on “Add Date” and enter the details of your date. This date will only appear on your calendar and will not be visible to your Connections.</p>
          </b-collapse>
        </b-card>
        
        
        <h3>TERMINATION</h3>
        <b-card class="mb-3">
          <div v-b-toggle.collapse-16 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question"> How can I delete my profile?</span>
          </div>
          <b-collapse id="collapse-16" class="mt-2">
            <p class="card-text answer"> Click on “Settings” and then click on “Delete Profile.”  When you select “Yes, delete” your profile will be permanently removed</p>
          </b-collapse>
        </b-card>        
        <!-- <b-card class="mb-3">
          <div v-b-toggle.collapse-3 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question"></span>
          </div>
          <b-collapse id="collapse-3" class="mt-2">
            <p class="card-text answer"></p>
          </b-collapse>
        </b-card> -->



        <!-- TEMPLATE -->
        <!-- <b-card class="mb-3">
          <div v-b-toggle.collapse-3 >
            <b-icon icon="chevron-right" class="mr-3"></b-icon>
            <span class="question"></span>
          </div>
          <b-collapse id="collapse-3" class="mt-2">
            <p class="card-text answer"></p>
          </b-collapse>
        </b-card> -->

        
                
      </b-col>
        
    </b-row>
    

  </b-container>
</template>

<script>
// import ContactFormAnonymous from '../components/ContactFormAnonymous.vue'
// import SmileySvg from '../../assets/icons/SmileySvg.vue'

export default {
  name: 'FaqPage',
  components: {
    // SmileySvg
    // ContactFormAnonymous
  },
  data() {
    return {

    }
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.currentUser
    // },
  },
	mounted() {
		window.scrollTo(0, 0)
  },  
  created() {

  },
  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
  // thin is thinner than light
  // font-family: Larsseit-Light;
}

.card {
  border-radius: 0.75rem;
}


div, span {
  font-family: Larsseit-Light;
}

.regular {
  font-size: 1.25rem;
}
.large {
  font-size: 2rem;
}

.small {
  font-size: 1rem;
}

</style>
